import "./App.css";
import axios from "axios";
import Box from "@mui/joy/Box";
import * as React from "react";
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import Key from "@mui/icons-material/Key";
import Button from "@mui/joy/Button";

function App() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState("");
  const minLength = 8;
  const addUser = () => {
    let body = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      mobile: phoneNumber,
      password: password,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/add_new_user`, body)
      .then((res) => {
        // console.log("SUCCESS");
        setSuccess(true);
        setEmail("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setPhoneNumber("");
      })
      .catch((err) => {
        // console.log(err.response);
        setError(true);
        setEmail("");
        setFirstName("");
        setLastName("");
        setPassword("");
        setPhoneNumber("");
      });
  };
  React.useEffect(() => {
    if (success) {
      setTimeout(() => setShow(true), 10000);
    }
  }, [success]);
  React.useEffect(() => {
    if (show) {
      setSuccess(false);
    }
  }, [show]);
  React.useEffect(() => {
    if (error) {
      setTimeout(() => setShowError(true), 10000);
    }
  }, [error]);
  React.useEffect(() => {
    if (showError) {
      setError(false);
    }
  }, [showError]);
  return (
    <div className="App">
      <header className="App-header">
        {success && (
          <Typography>
            The user was added, you can now add a new one.
          </Typography>
        )}
        {error && (
          <Typography color="danger">
            There is already a user with this email, please use a different one
          </Typography>
        )}
        <Box
          sx={{
            py: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
              addUser();
            }}
          >
            <Input
              required
              placeholder="Enter your First Name*"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <Input
              required
              placeholder="Enter your Last Name*"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <Input
              required
              placeholder="Enter your Email*"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <Input
              placeholder="Enter your Phone Number"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
            <Stack
              spacing={0.5}
              sx={{
                "--hue": Math.min(password.length * 10, 120),
              }}
            >
              <Input
                required
                type="password"
                placeholder="Enter your password*"
                startDecorator={<Key />}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />

              <LinearProgress
                determinate
                size="sm"
                value={Math.min((password.length * 100) / minLength, 100)}
                sx={{
                  bgcolor: "background.level3",
                  color: "hsl(var(--hue) 80% 40%)",
                }}
              />
              <Typography
                level="body3"
                sx={{ alignSelf: "flex-end", color: "hsl(var(--hue) 80% 30%)" }}
              >
                {password.length < 3 && "Very weak"}
                {password.length >= 3 && password.length < 6 && "Weak"}
                {password.length >= 6 && password.length < 10 && "Strong"}
                {password.length >= 10 && "Very strong"}
              </Typography>
            </Stack>

            <Button type="submit">Submit</Button>
          </form>
        </Box>
      </header>
    </div>
  );
}

export default App;
